<template>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="tg-sectionspace tg-haslayout">
      <div class="container" style="padding: 0 50px;">
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div class="classify-container">
              <div class="classify-container__header">
                图书分类
              </div>
              <div class="classify-container__content">
                <div v-for="i in types.secondType" :key="i._id" class="classify-container__level1">
                  <div class="classify-container__name">
                    {{ i.name }}
                  </div>
                  <div class="classify-container__subname">
                    <a
                      class="classify-container__subname-item"
                      v-for="k in i.bookType"
                      :key="k._id"
                      href="javascript:;"
                      @click="onTypeChange(i, k)"
                    >
                      {{ k.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-lg-2" v-for="(book, index) in [...books]" :key="index">
            <book style="margin-bottom: 29px;" :book="book" />
          </div>
        </div>
        <div class="row" style="display:flex; justify-content: center;">
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :total="basicdata.total"
            :current-page="basicdata.page"
            :page-size="basicdata.pagesize"
            @current-change="onPageChange"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Pagination } from 'element-ui'
import listMixin from '../plugins/mixins/list'
import { CommonQuery, SingleQuery } from '../api/commonquery'
import Book from '../components/Book.vue'
export default {
  mixins: [listMixin],
  components: {
    Book,
    [Pagination.name]: Pagination,
  },
  props: {
    topType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      basicdata: {
        searchkey: '',
        placeholder: '搜索图书、ISBN、作者',
        total: 1,
        pagesize: 20,
        page: 1,
      },
      sort: '',
      books: [],
      types: [],
    }
  },
  watch: {
    topType() {
      console.log(this.topType)
      this.getTypes().then(() => {
        this.getData()
      })
    },
  },
  methods: {
    async getData(newQuery = {}) {
      this.initbasicData(newQuery)
      let data = {
        model: 'book',
        query: {
          topType: {
            $eq: this.types._id,
          },
        },
        skip: (this.basicdata.page - 1) * this.basicdata.pagesize,
        limit: this.basicdata.pagesize,
      }

      if (newQuery.type) {
        Object.assign(data.query, {
          thirdType: {
            $eq: newQuery.type,
          },
        })
      }

      let res = await CommonQuery(data)
      this.books = res.data.map((book) => {
        return {
          ...book,
          cover: book.cover + '?imageMogr2/thumbnail/220x330!',
          readed: book.readed || 0,
          to: {
            name: 'bookDetail',
            query: {
              id: book._id,
            },
          },
        }
      })
      this.basicdata.total = res.total
    },

    async getTypes() {
      let id = ''
      switch (this.topType) {
        case 'music':
          id = '287a53aa61aced8100930cf21e1f50fb'
          break
        case 'child':
          id = '0448022461aced8b00a5762131dadaee'
          break
        case 'ebook':
          id = '381d149061aceda200b7aff722a7d2de'
          break
      }
      let data = {
        model: 'bookTopType',
        id,
      }
      let res = await SingleQuery(data)
      this.types = res.data
      console.log(this.types)
    },

    onTypeChange(secondType, thirdType) {
      this.$router.push({
        name: 'search',
        params: { defaultActive: 'book' },
        query: { topType: this.types._id, secondType: secondType._id, thirdType: thirdType._id },
      })
    },

    onPageChange(newPage) {
      let query = { ...this.$route.query }
      Object.assign(query, {
        page: newPage,
      })
      this.changeSelectUrl(query)
    },
  },
  created() {
    this.getTypes().then(() => {
      this.getData(this.$route.query)
    })
  },
}
</script>

<style scoped>
.classify-container {
  border: 1px solid #f05000;
  width: 100%;
  border-radius: 5px;
}

.classify-container__header {
  padding: 5px 20px;
  color: white;
  font-size: 18px;
  background: #f05000;
}

.classify-container__content {
  padding: 0 15px 17px 15px;
}

.classify-container__name {
  color: #f05000;
  font-size: 16px;
  border-bottom: 1px dotted #f05000;
  padding: 9px 0;
}

.classify-container__subname-item {
  font-size: 12px;
  margin-top: 6px;
  border-left: 1px solid rgba(1, 1, 1, 0.2);
  display: inline-block;
  margin-left: -1px;
  padding: 0px 8px 0px 8px;
  color: gray;
}

.classify-container__subname-item:hover {
  color: #f05000;
}
</style>
