<template>
  <div class="item">
    <div class="postbook">
      <div class="bookimg">
        <div class="frontcover">
          <router-link :to="book.to" tag="a" target="_blank">
            <img :src="book.coverUrl" style="width: 173px; height:173px;object-contain:cover;" />
          </router-link>
        </div>
      </div>
      <div class="postbookcontent">
        <div class="booktitle">
          <router-link tag="a" target="_blank" :to="book.to">{{ book.title }}</router-link>
        </div>
        <div class="bookdesc">{{ book.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Book',
  props: ['book'],
}
</script>

<style scoped>
.booktitle {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bookdesc {
  font-size: 14px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.4);
}
</style>
